/**
 * Fiwe - 2023
 * Wrapper for price display components.
 * Gets the price for a catentryId from the backend.
 * Parameters:
 * - catentryId (mandatory).
 * - activeOrg, used for cache in B2B if user can change organization.
 * - quantity, if quantity dependent price is used in B2B.
 */
import { usePrice } from '@/data/Content/Price';
import { CustomGetPriceResult, CustomPriceInput } from '@/data/types/CustomPrice';
import { PropsWithChildren, createContext } from 'react';

export const PriceContext = createContext({
	priceAmount: -1,
	price: '',
	unit: '',
	comparisonFactor: 1,
	priceAmountAlternateUnit: -1,
	priceAlternateUnit: '',
	alternateUnit: '',
	fromPrice: false,
	showAltUnitAsPrimary: false,
	priceType: '',
});

export const PriceWrapper = (props: PropsWithChildren<CustomPriceInput>) => {
	const { catentryId } = props;
	const extPrice = usePrice(catentryId !== undefined ? catentryId : '');

	if (extPrice !== undefined) {
		return (
			<PriceContext.Provider value={extPrice as CustomGetPriceResult}>
				{props?.children}
			</PriceContext.Provider>
		);
	}
	return null;
};
