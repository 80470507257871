/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */
import { useNextRouter } from '@/data/Content/_NextRouter';
import { DATA_KEY, fetcher } from '@/data/Content/_Price';
import { useSettings } from '@/data/Settings';
import { ProjectContext } from '@/data/context/project';
import { getActiveOrganizationId } from '@/data/utils/getActiveOrganizationId';
import { getClientSideCommon } from '@/data/utils/getClientSideCommon';
import { useContext } from 'react';
import useSWR from 'swr';

export const usePrice = (catentryId: string) => {
	const router = useNextRouter();
	const { settings } = useSettings();
	const { storeId } = getClientSideCommon(settings, router);
	const { currentProjectId } = useContext(ProjectContext);

	const { data: price, mutate } = useSWR(
		storeId
			? [{ storeId }, DATA_KEY, catentryId, currentProjectId ?? '', getActiveOrganizationId]
			: null,
		async ([{ storeId }]) => fetcher(true)(storeId, catentryId),
		{ revalidateOnFocus: false }
	);

	return {
		...price,
		mutatePrice: mutate,
	};
};
