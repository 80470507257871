/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const productCardContentSX: SxProps<Theme> = (theme: Theme) => ({
	py: 0,
	flex: '1',
	marginBottom: theme.spacing(1),
	padding: 0,
});

export const productCardDividerSX: SxProps<Theme> = (theme: Theme) => ({
	borderTop: '2px solid',
	borderColor: theme.palette.primary.light,
});
