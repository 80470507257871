/**
 * Fiwe - 2023
 * Price display with large price
 * Parameters:
 * - priceData
 */

import { PriceContext } from '@/components/blocks/FiweShared/Prices/PriceWrapper';
import { PriceTypeIcon } from '@/components/blocks/FiweShared/Prices/types/PriceTypeIcon';
import { Paper, Skeleton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext } from 'react';

export const MediumPrice = () => {
	const priceData = useContext(PriceContext);

	const showAltUnitAsPrimary = priceData?.showAltUnitAsPrimary;
	let mainPrice;
	let comparisonPrice;
	if (showAltUnitAsPrimary) {
		mainPrice = priceData?.priceAlternateUnit;
		comparisonPrice = priceData?.price;
	} else {
		mainPrice = priceData?.price;
		comparisonPrice = priceData?.priceAlternateUnit;
	}

	const PrimaryPrice = styled(Paper)(({ theme }) => ({
		...theme.typography.body2,
		padding: theme.spacing(0),
		textAlign: 'right',
		color: theme.palette.text.primary,
		boxShadow: 'none',
	}));

	const ComparisonPrice = styled(Paper)(({ theme }) => ({
		...theme.typography.caption,
		padding: theme.spacing(0),
		textAlign: 'right',
		color: theme.palette.text.secondary,
		boxShadow: 'none',
	}));

	return (
		<Stack direction="row" spacing={2}>
			{priceData?.priceAmount && priceData?.priceAmount > 0 ? (
				<Stack spacing={0}>
					<Stack direction={'row'} alignItems={'center'} spacing={1.5}>
						<PriceTypeIcon sx={{ borderColor: 'primary.main' }} />
						<PrimaryPrice>{mainPrice}</PrimaryPrice>
					</Stack>
					{priceData?.priceAmountAlternateUnit && priceData?.priceAmountAlternateUnit > 0 ? (
						<ComparisonPrice>{comparisonPrice}</ComparisonPrice>
					) : null}
				</Stack>
			) : (
				<Stack alignItems={'flex-end'}>
					<Skeleton variant="text" width={'18ch'}>
						<PrimaryPrice>{'\u00A0'}</PrimaryPrice>
					</Skeleton>
					<Skeleton variant="text" width={'6ch'}>
						<ComparisonPrice>{'\u00A0'}</ComparisonPrice>
					</Skeleton>
				</Stack>
			)}
		</Stack>
	);
};
