/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { PriceWrapper } from '@/components/blocks/FiweShared/Prices/PriceWrapper';
import { MediumPrice } from '@/components/blocks/FiweShared/Prices/types/MediumPrice';
import { PriceTypeIcon } from '@/components/blocks/FiweShared/Prices/types/PriceTypeIcon';
import { Linkable } from '@/components/blocks/Linkable';
import { LoggedIn } from '@/components/blocks/LoggedIn';
import { MuiCardMedia } from '@/components/blocks/MuiCardMedia';
import { ProductCardCompareBox } from '@/components/blocks/ProductCard/parts/CompareBox';
import { productCardSX } from '@/components/blocks/ProductCard/styles/card';
import {
	productCardContentSX,
	productCardDividerSX,
} from '@/components/blocks/ProductCard/styles/cardContent';
import {
	productCardMediaPriceTypeSX,
	productCardMediaScalingSX,
	productCardMediaSX,
} from '@/components/blocks/ProductCard/styles/cardMedia';
import { productCardNameSX } from '@/components/blocks/ProductCard/styles/name';
import { Swatch } from '@/components/blocks/Swatch';
import { useProductCard } from '@/data/Content/_ProductCard';
import { useProductEvents } from '@/data/Content/_ProductEvents';
import { ProductType } from '@/data/types/Product';
import { combineSX } from '@/utils/combineSX';
import { Box, Card, CardActions, CardContent, Stack, Typography } from '@mui/material';
import { FC } from 'react';

export const ProductCard: FC<{
	product: ProductType;
	clickAction?: () => void;
}> = ({ product, clickAction }) => {
	const { onSwatch, sku } = useProductCard(product);
	const { onClick } = useProductEvents({ product });
	const scalingValues = getImageScalingValues(product);

	return (
		<Card onClick={onClick(clickAction)} elevation={0} sx={productCardSX}>
			<Linkable href={product.seo?.href} color="textPrimary" width={'100%'}>
				<MuiCardMedia
					sx={combineSX([productCardMediaSX, productCardMediaScalingSX(scalingValues)])}
					component="div"
					image={(sku ?? product).thumbnail}
					title={product.name}
				></MuiCardMedia>
				<PriceWrapper catentryId={(sku ?? product).id} quantity={1}>
					<PriceTypeIcon sx={productCardMediaPriceTypeSX} />
				</PriceWrapper>
			</Linkable>
			<CardContent sx={productCardContentSX}>
				<Stack spacing={1}>
					<Linkable href={product.seo?.href} color="textPrimary">
						<Typography
							component={'h2'}
							id={`${product.partNumber}-name`}
							data-testid={`${product.partNumber}-name`}
							sx={productCardNameSX}
						>
							{product.name}
						</Typography>
					</Linkable>
					{product.colorSwatches.length > 0 ? (
						<Stack direction="row" justifyContent="center" spacing={1}>
							{product.colorSwatches.map((colorSwatch) => (
								<Swatch
									key={colorSwatch.identifier}
									title={colorSwatch.identifier}
									size="medium"
									image={colorSwatch.image1path}
									data-testid={`product-${colorSwatch.identifier.toLowerCase()}-swatch`}
									id={`product-${colorSwatch.identifier.toLowerCase()}-swatch`}
									onClick={(event) => onSwatch(event, colorSwatch)}
								/>
							))}
						</Stack>
					) : null}
					<Box sx={productCardDividerSX}></Box>
					<Stack direction="row" justifyContent="right" spacing={1}>
						<PriceWrapper catentryId={(sku ?? product).id} quantity={1}>
							<MediumPrice />
						</PriceWrapper>
					</Stack>
				</Stack>
			</CardContent>
			<LoggedIn>
				<CardActions>
					<ProductCardCompareBox product={product} />
				</CardActions>
			</LoggedIn>
		</Card>
	);
};

const getImageScalingValues = (product: ProductType) => {
	let scaleWidth = 0;
	let scaleHeight = 0;
	product.attributes.map((attribute) => {
		if (attribute.identifier.endsWith('EF020852_FLOAT')) {
			// Längd kakel på matta (mm)_EF020852_FLOAT
			scaleHeight = Number(attribute.values[0].value);
		} else if (attribute.identifier.endsWith('EF020853_FLOAT')) {
			// Bredd kakel på matta (mm)_EF020853_FLOAT
			scaleWidth = Number(attribute.values[0].value);
		} else if (attribute.identifier.endsWith('EF020848_FLOAT')) {
			// Längd (nominell) (mm)_EF020848_FLOAT
			scaleHeight = scaleHeight === 0 ? Number(attribute.values[0].value) : scaleHeight;
		} else if (attribute.identifier.endsWith('EF020849_FLOAT')) {
			// Bredd (nominell) (mm)_EF020849_FLOAT
			scaleWidth = scaleWidth === 0 ? Number(attribute.values[0].value) : scaleWidth;
		}
	});
	if (scaleWidth === 0 || scaleHeight === 0 || scaleWidth > 600 || scaleHeight > 600) {
		scaleWidth = 600;
		scaleHeight = 600;
	}
	return { scaleWidth, scaleHeight, widthIsLarger: scaleWidth > scaleHeight };
};
