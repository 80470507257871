/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const productCardMediaSX: SxProps<Theme> = {
	mb: 1,
	mx: 'auto',
	backgroundSize: 'cover',
	position: 'relative',
	// height: (theme) => theme.dimensions.productCard.thumbnail + 'px',
	paddingTop: '100%',
};

export const productCardMediaPriceTypeSX: SxProps<Theme> = {
	position: 'absolute',
	top: '.5rem',
	left: '.5rem',
	color: 'white.main',
	borderColor: 'white.main',
};

export const productCardMediaScalingSX = (scalingValues: {
	scaleWidth: number;
	scaleHeight: number;
	widthIsLarger: boolean;
}): SxProps<Theme> => ({
	width: `calc((${
		scalingValues.widthIsLarger ? scalingValues.scaleWidth : scalingValues.scaleHeight
	}/600) * 100%)`,
	backgroundSize: 'contain',
	backgroundPosition: 'bottom',
});
