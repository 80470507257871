/**
 * Fiwe - 2023
 * Price display with large price
 * Parameters:
 * - priceData
 */

import { PriceContext } from '@/components/blocks/FiweShared/Prices/PriceWrapper';
import { useLocalization } from '@/data/Localization';
import { Box, SxProps, Theme, Tooltip } from '@mui/material';
import { useContext } from 'react';

export const PriceTypeIcon = ({ sx }: { sx?: SxProps<Theme> }) => {
	const localization = useLocalization('PriceDisplay');
	const priceData = useContext(PriceContext);

	return priceData.priceType?.length ? (
		priceData.priceType === 'P' || priceData.priceType === 'A' ? (
			<Tooltip
				title={
					priceData.priceType === 'P'
						? localization.Help.P.t()
						: priceData.priceType === 'A'
						? localization.Help.A.t()
						: null
				}
			>
				<Box
					sx={{
						display: 'inline-flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '1.5rem',
						width: '1.5rem',
						padding: '.25rem',
						border: '2px solid #22222222',
						fontWeight: 700,
						fontFamily: 'GT-America-Condensed, GT-America, Roboto, sans-serif',
						cursor: 'help',
						lineHeight: 'normal',
						marginRight: '.5rem',
						...sx,
					}}
				>
					{priceData.priceType}
				</Box>
			</Tooltip>
		) : (
			<Box sx={{ fontSize: '0.75rem', color: '#970606' }}>{priceData.priceType}</Box>
		)
	) : null;
};

// fortsatt med localization och tooltip
