/*
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2023.
 */
import { SxProps, Theme } from '@mui/material';

export const productCardNameSX: SxProps<Theme> = (theme: Theme) => ({
	whiteSpace: 'normal',
	overflowWrap: 'break-word',
	wordWrap: 'break-word',
	wordBreak: 'break-word',
	fontFamily: 'GT-America-Condensed, GT-America, Roboto, sans-serif',
	fontWeight: 700,
	textTransform: 'uppercase',
	lineHeight: 1.2,
	minHeight: '2.4em',
	textOverflow: 'ellipsis',
	lineClamp: 2,
	overflow: 'auto',
	fontSize: { xs: '.8em', md: '1em' },
});
