/**
 * Licensed Materials - Property of FIWE.
 * (C) Copyright FIWE  2023.
 */

import { customPrice } from 'integration/generated/custom';
import { CustomGetPriceResult } from 'integration/generated/custom/data-contracts';

/**
 * This REST service method returns price information.
 */
export const DATA_KEY = 'customPrice';

export const fetcher =
	(pub: boolean) =>
	/**
	 * The data fetcher for Price
	 * @param query The request query.
	 *
	 * @returns Fetched Price .
	 */
	async (storeId: string, catentryId: string): Promise<CustomGetPriceResult | undefined> => {
		try {
			return await (customPrice(pub).priceByCatentryId(
				storeId,
				catentryId
			) as Promise<unknown> as Promise<CustomGetPriceResult>);
		} catch (error: any) {
			console.error(error);
			return undefined;
		}
	};
